const Figma = ({ fill }) => {
  return (
    <svg
      width="22"
      height="32"
      viewBox="0 0 22 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 6C0 2.686 2.686 0 6 0H16C19.314 0 22 2.686 22 6C22 8.086 20.934 9.926 19.318 11C20.934 12.074 22 13.914 22 16C22 19.314 19.314 22 16 22C14.464 22 13.06 21.422 12 20.472V26C12 29.314 9.314 32 6 32C2.686 32 0 29.314 0 26C0 23.912 1.066 22.076 2.682 21C1.066 19.924 0 18.088 0 16C0 13.914 1.066 12.074 2.682 11C1.066 9.926 0 8.086 0 6ZM6 10C3.79 10 2 8.21 2 6C2 3.79 3.79 2 6 2H10V10H6ZM10 12H6C3.79 12 2 13.79 2 16C2 18.21 3.79 20 6 20H10V12ZM10 22H6C3.79 22 2 23.79 2 26C2 28.21 3.79 30 6 30C8.21 30 10 28.21 10 26V22ZM16 12C13.79 12 12 13.79 12 16C12 18.21 13.79 20 16 20C18.21 20 20 18.21 20 16C20 13.79 18.21 12 16 12ZM16 10C18.21 10 20 8.21 20 6C20 3.79 18.21 2 16 2H12V10H16Z"
        fill={fill}
      />
    </svg>
  )
}

export default Figma
